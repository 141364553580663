//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'Footer',
  components: {
    FooterListNavbar: () => import('./components/FooterListNavbar.vue'),
    FooterContacts: () => import('./components/FooterContacts.vue'),
  },
  data() {
    return {
      links: [],
      accordion: false,
    }
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
  async mounted() {
    await this.fetchRouting()
    this.initAccordionNavbar()
    window.addEventListener('resize', this.initAccordionNavbar)

    let word = 'Meble'
    if (document.querySelector('.policy-text').textContent.includes(word)) {
      document.querySelector('.policy-text').textContent = document
        .querySelector('.policy-text')
        .textContent.replace(word, 'interni24.pl')
    }
  },
  methods: {
    fetchRouting() {
      return this.$request.get('/menu/footer').then(({ data }) => {
        const array = data.map((i) => ({
          id: i.id,
          title: i.name,
          order: i.order,
          parentId: i.parent_id || null,
          ...(i.link && { link: i.link }),
        }))
        const links = []
        array.forEach((item, i, self) => {
          const parent = self.find((p) => p.id === item.parentId)
          if (parent) {
            parent.children
              ? parent.children.push(item)
              : (parent.children = [item])
          } else {
            links.push(item)
          }
        })
        this.links = links
      })
    },
    initAccordionNavbar() {
      if (document.getElementById('wrapper')) {
        const widthNavbarWrapper =
          document.getElementById('wrapper').offsetWidth
        const widthNavbarList =
          document.getElementById('footerNavbar').offsetWidth
        const widthFooterContacts =
          document.getElementById('footerContacts').offsetWidth
        if (1320 > widthNavbarWrapper) {
          this.accordion = true
        } else {
          this.accordion = false
        }
      }
    },
  },
}
